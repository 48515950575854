import initializer from '@/lib/initializer'
import lazyModule from '@/lib/lazy-module'
import 'vite/modulepreload-polyfill'
import { initDisclosureWidgets } from '@/lib/a11y'
import revive from '@/lib/revive.js'
import { initTabKeyListener } from '@/lib/accessibility'
import { initBluecore } from '@/lib/bluecore'
import scrollAnimation from '@/lib/scroll-animation'
import sal from 'sal.js'

const islands = import.meta.glob('@/islands/**.js')
const summaries = document.querySelectorAll('[id^="Details-"] summary')

revive(islands)
initDisclosureWidgets(summaries)

if (window.decodeURI(window.location.pathname) === '/account/addresses') {
  import('@/lib/shopify_common')
  import('@/lib/customer-address')
}

import picture from '../../modules/picture/picture'
import productCard from '../../modules/product-card/product-card-mixin'
import carouselSwiper from '../../modules/carousel-swiper/carousel-swiper'
import header from '../../modules/header/header'
import footer from '../../modules/footer/footer'
import promobar from '../../modules/promobar/promobar'
import hero from '../../modules/hero/hero'
import marquee from '../../modules/marquee/marquee'
import featuredProduct from '../../modules/featured-product/featured-product'
import productCarousel from '../../modules/product-carousel/product-carousel'
import cartGrid from '../../modules/cart-grid/cart-grid'
import miniCart from '../../modules/mini-cart/mini-cart'
import emailCollection from '../../modules/email-collection/email-collection'
import fullWidth from '../../modules/full-width/full-width'
const newsletterForm = lazyModule('newsletterForm', { offset: 120 })

document.addEventListener('DOMContentLoaded', () => {
  initializer({
    picture,
    productCard,
    carouselSwiper,
    promobar,
    header,
    hero,
    marquee,
    featuredProduct,
    productCarousel,
    cartGrid,
    miniCart,
    emailCollection,
    footer,
    newsletterForm,
    fullWidth
  })

  initTabKeyListener()
  scrollAnimation()
  initBluecore()
  // Do not remove "window.scrollAnimations" because it's currently used in multiple places
  window.scrollAnimations = sal({
    threshold: 0.1,
  })
})